import {getFormattedMoney, getPricingOptionLabel} from '@wix/wix-events-commons-statics'
import React from 'react'
import classNames from 'classnames'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import {getFlexJustifyContentClass} from '../../../../alignment'
import {Tax} from '../../../parts/tax'
import {WixFee} from '../../../parts/wix-fee'
import s from './pricing-option-price.scss'
import {PricingOptionsPriceProps} from './interfaces'

export const PricingOptionPrice = ({pricingOption, className, ticket, contentAlignment}: PricingOptionsPriceProps) => {
  const {isMobile} = useEnvironment()
  const price = isMobile ? getPricingOptionLabel(ticket, pricingOption.id) : getFormattedMoney(pricingOption.price)
  const {t} = useTranslation()

  return (
    <div className={className} role="cell">
      <p className={s.price} data-hook="price" aria-label={t('mobile.thanks.price', {price})}>
        {price}
      </p>

      <div
        className={classNames({
          [s.mobileFeesContainer]: isMobile,
          [getFlexJustifyContentClass(contentAlignment)]: isMobile,
        })}
      >
        <Tax ticket={ticket} overridePrice={pricingOption.price.amount} />
        <WixFee ticket={ticket} overridePrice={pricingOption.price.amount} />
      </div>
    </div>
  )
}
