import React, {useState} from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getPricingOptions} from '@wix/wix-events-commons-statics'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {ExpansionButton} from '../../parts/expansion-button'
import {PricingOption} from '../pricing-option'
import {PricingOptionsProps} from './interfaces'

export const PricingOptions = ({ticket, readonly}: PricingOptionsProps) => {
  const [expanded, setExpanded] = useState(false)
  const {t} = useTranslation()

  const buttonText = expanded
    ? 'ticketsPicker.ticketDescription.showLessPricingOptions'
    : 'ticketsPicker.ticketDescription.showMorePricingOptions'

  const pricingOptions = getPricingOptions(ticket)
  const visibleOptions = expanded ? pricingOptions : [pricingOptions[0]]

  return (
    <>
      {visibleOptions?.map(pricingOption => (
        <PricingOption key={pricingOption.id} pricingOption={pricingOption} ticket={ticket} readonly={readonly} />
      ))}
      {pricingOptions.length > 1 ? (
        <ExpansionButton dataHook={DH.MORE_PRICE_OPTIONS_BUTTON} expanded={expanded} onChange={setExpanded}>
          {t(buttonText)}
        </ExpansionButton>
      ) : null}
    </>
  )
}
