import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'

export interface ItemWithTicket extends wix.events.ticketing.Item {
  ticket: wix.events.ticketing.Ticket
}

export const getInvoiceItemsWithTicketInfo = (
  items: wix.events.ticketing.Item[],
  tickets: wix.events.ticketing.Ticket[],
): ItemWithTicket[] =>
  items.map(item => ({
    ...item,
    ticket: tickets.find(ticket => ticket.ticketDefinitionId === item.id),
  }))

export const getTicketPriceText = (price: wix.events.Money, t: TFunction) =>
  Number(price.amount) ? getFormattedMoney(price) : t('ticketPrice.free')

export const getPlaceInfoFromTicket = (ticket: wix.events.ticketing.Ticket) => ({
  area: ticket?.ticketDetails?.areaName,
  table: ticket?.ticketDetails?.tableName,
})

export const getLabeledItemTicketPrice = (item: ItemWithTicket, t: TFunction) => {
  let ticketPrice = getTicketPriceText(item.price, t)
  if (item.ticket?.ticketDetails?.pricingOptionName) {
    ticketPrice = `${item.ticket.ticketDetails.pricingOptionName} - ${ticketPrice}`
  }
  return ticketPrice
}
